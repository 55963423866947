@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 24px;
  padding-left: 0px;
}

.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 4px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #1263a5;
  background-color: #156a8f !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  width: 78%;
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#1263a5, #1263a5);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #1263a5;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.f-logo {
  width: 40%;
}
.Footer1 img {
  margin-top: 40px;
  width: 100%;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.HeroSection {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8%;
  padding-bottom: 4%;
  color: white;
  text-align: center;
}
.HeroSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1263a585; /* Black overlay with 50% opacity */
  z-index: 0; /* Position the overlay behind the content */
}
.HeroSection .btn-group {
  justify-content: center;
}

.hero-content {
  max-width: 600px;
  z-index: 1; /* Ensure content is above background */
}

.HeroSection h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.HeroSection h2 {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.HeroSection .hero-text {
  font-size: 1.2rem;
  line-height: 1.6;
}
.whyChooseUs {
  padding: 2% 8%;
  background-color: #f7f7f7;
}
.whyChooseUs .btn-group {
  justify-content: center;
}
.why-choose-us-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whyChooseUs h2 {
  text-align: center;
  font-size: 3rem;
}

/* .why-choose-us-left {
  width: 30%;
} */

.why-choose-us-center {
  position: relative;
  width: 50%;
  text-align: center;
}

.why-choose-us-center img {
  max-width: 100%;
  height: auto;
}

.why-choose-us-right {
  width: 40%;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  background-color: #8eac26;
  border-radius: 50%;
  position: absolute;
  left: -155px;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-content {
  background-color: #1263a5;
  padding: 20px;
  border-left: 5px solid #8eac26;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 20px;
}

.step-content h4 {
  margin: 0 0 10px 0;
  color: white;
  font-size: 1.3rem;
}

.step-content p {
  margin: 0;
  color: white;
  font-size: 1.1rem;
}

.contact-info {
  margin-top: 30px;
  text-align: center;
  color: white;
}
.step_img {
  height: 60px;
  width: 16%;
}
.step_c {
  width: 84%;
}
.step_img img {
  width: 100%;
}
.HeroSection br {
  display: none;
}
.contact-section {
  position: relative;
  width: 80%;
  height: 60vh;
  background-image: url("./Component/assets/images/photos/accident-warehouse-man-floor.webp"); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0% 8%; */
  margin: 0 auto;
  border-radius: 24px;
  box-shadow: rgb(9 9 10 / 90%) 0px 7px 29px 0;
  margin-top: 30px;
}

.contact-overlay {
  position: absolute;
  width: 100%; /* 80% of the screen */
  height: 100%;
  background-color: #1263a585; /* Overlay with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  flex-direction: column;
}

.contact-heading {
  color: white;
  font-size: 2.4rem;
  text-align: center;
  max-width: 600px;
}
.services-section {
  display: flex;
  align-items: center;
  justify-content: center;
 
  background: linear-gradient(135deg, #8eac26 50%, #8eac26 50%);
  padding: 2rem;
}

.services-content {
  text-align: center;
  max-width: 600px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.services-description {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #666;
}
.elementor-widget-container {
  padding: 0% 8%;
}
.elementor-widget-container p {
  line-height: 26px;
  font-size: 20px;
}
.elementor-widget-container h1 {
  font-size: 36px;
}
.elementor h2 {
  font-size: 28px;
  color: #1263a5;
}
.elementor h4 {
  color: #1263a5;
}
.elementor h4 {
  font-size: 22px;
}
.elementor section:first-of-type {
  background-color: #1263a5;
  padding: 10% 0%;
  color: white;
  padding-bottom: 5%;
}
.elementor section:first-of-type p a strong {
  color: white !important;
}
.a {
  text-decoration: none;
  color: white;
}
.elementor p strong {
  color: #ffffff;
}

.elementor p b {
  color: #000000;
}
.elementor li strong {
  color: #1263a5;
}
.elementor p a {
  text-decoration: none;
  color: #8eac26;
}
.elementor a {
  text-decoration: none;
  color: #8eac26;
}
/* Services.css */
.services-section {
  padding: 50px 20px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-content {
  max-width: 1200px;
  text-align: center;
}

.services-heading {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.services-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.services-card {
  position: relative;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  flex: 1;
  min-width: 300px;
  overflow: hidden;
}
.services-card img {
  width: 70px;
  height: 70px;
  filter: invert(1);
}

.services-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: #1263a5;
}
.services-card:hover img{
  filter: invert(0);
}

.services-card:hover .services-card-title,
.services-card:hover .services-card-description {
  color: #fff;
}

.services-card-number {
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.services-card:hover .services-card-number {
  transform: rotate(-10deg);
  opacity: 1;
}

.services-card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  transition: color 0.3s ease;
}

.services-card-description {
  font-size: 1.2rem;
  color: #161616;
  transition: color 0.3s ease;
}
.contact-section br{
  display: none;
}
@media (max-width: 768px) {
  .Navbar {
    display: none;
  }
  .contact-section br{
    display: block;
  }
  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }

  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #1263a5;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #1263a5; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #074374; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }

  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }

  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }

  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .topFixed img {
    width: 100%;
  }
  .HeroSection h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .HeroSection h2 {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  .whyChooseUs h2 {
    text-align: center;
    font-size: 2rem;
  }

  .why-choose-us-container {
    flex-direction: column;
  }
  .why-choose-us-center {
    width: 100%;
  }
  .why-choose-us-right {
    width: 100%;
  }
  .step-number {
    display: none;
  }
  .contact-heading {
    color: white;
    font-size: 1.7rem;
    text-align: center;
    max-width: 600px;
  }
  .elementor-widget-container h1 {
    font-size: 32px;
  }
  .elementor h2 {
    font-size: 25px;
    color: #b19f3d;
  }
  .elementor h4 {
    color: #b19f3d;
  }
  .elementor h4 {
    font-size: 20px;
  }
  .elementor-widget-container p {
    line-height: 23px;
    font-size: 18px;
  }
  .step-content {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  .step_img {
    height: 60px;
    width: 100%;
  }
  .step_img img {
    width: 25%;
  }
  .step_c {
    width: 100%;
  }
  .step-content p {
    text-align: left;
    font-size: 1.1rem;
  }
  .services-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #666;
    text-align: left;
  }
  .services-heading {
    font-size: 2rem;
  }
  .HeroSection {
    position: relative;
    height: 50vh;
  }

  .f-logo {
    width: 100%;
  }
  .HeroSection br {
    display: block;
  }
  html{
    overflow-x: hidden;
  }
  .contact-section{
    height: 50vh;
    width: 90%;
  }
  .contact-overlay .btn-group{
    width: 90%;
  }
  .services-card-title{
    font-size: 1.3rem;
  }
}
